
import hasPageMeta from '@/mixins/hasPageMeta'

export default {
    name: 'ErrorPage',

    mixins: [hasPageMeta],

    props: {
        model: {
            type: Object,
            default: () => {},
        },

        // eslint-disable-next-line vue/require-default-prop
        errorCode: null,
        // eslint-disable-next-line vue/require-default-prop
        errorMsg: null,
    },

    computed: {
        siteTitle() {
            return 'Page not found'
        },

        siteDescription() {
            return 'Something seems to be missing here'
        },

        previewImage() {
            return '/404.png'
        },
    },
}
